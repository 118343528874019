/* TODO Add site wide styles */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');

:root {
  --main-color: #31C48D;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
}

body {
  margin: 0 auto;
}

button:hover {
  cursor: pointer;
}

.container {
  min-height: 100vh - 100px;
  position: relative;
}
