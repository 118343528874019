.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.signup > form {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
gap: 10px;
}

.signup > form > label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup > form > button {
  width: 100%;
}
