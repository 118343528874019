.listing-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0,0,0, 0.6);
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s ease-in;
}

.listing-card-container:hover {
  box-shadow: 0 0 20px rgba(0,0,0, 0.6);;
}

.listing-card-container:hover {
  cursor: pointer;
}

.price-and-other {
  display: flex;
  justify-content: space-between;
}

.listing-card__details {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}

.listing-card__owner {
  margin: 10px 0;
}

.listing-card__buttons {
  display: flex;
  gap: 10px;
}

.listing-card__price {
  font-weight: bold;
}

.listing-card__name {
  width: inherit
}

.stars {
  margin: 15px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.like-card {
  position: absolute;
}

.fa-heart {
  width: fit-content;
}
