.profile-dropdown {
  position: absolute;
  border: 1px solid black;
  background-color: white;
  list-style: none;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.no-user-btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-user-btns > * {
  border: 1px solid black;
  background-color: white;
  padding: 5px 15px;
}

.hidden {
  display: none;
}

.nav-bar {
  padding: 15px;
  width: 75%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin: 0 auto;
  gap: 15px;
}

.nav-container {
  border-bottom: 1px solid grey;
}

.logo {
  background-image: url(./logo-no-background.png);
  height: 50px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-right-side {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  justify-self: flex-end;
}

.fa-heart {
  font-size: 25px;
}

.fas:hover, .far:hover {
  cursor: pointer;
}

input[type="search"] {
  width: 100%;
  border: none;
  padding: 8px;
  font-size: 14px;
  background-color: transparent;
}

input[type="search"]:focus {
  border: none;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 24px;
  padding: 8px;
}

.search-icon {
  display: flex;
  align-items: center;
  padding: 4px;
}

.search-icon:hover {
  cursor: pointer;
}

.search-btn {
  border: none;
}

.create-listing {
  color: white;
  background-color: #31C48D;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.profile-btn {
  border: none;
  background-color: white;
}

.fa-user-circle {
  font-size: 25px;
}

.logout-btn {
  background-color: #31C48D;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.cart-logo {
  position: relative;
}

.cart-logo__amount {

  position: absolute;
  right: -25%;
  background-color: #31C48D;
  padding: 0 5px;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  top: 10px;
}

.cart-logo__amount:hover {
  cursor: pointer;
}

.search-thing {
  /* border: none; */
  outline: none;
}
