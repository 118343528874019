.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.form-container > form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 50%;
  gap: 20px;
}

.form-container > form > label {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.form-container > form > label> input, select, textarea {
  padding: 15px;
  border-radius: 5px;
}

textarea {
  resize: none;
  height: 100px;
}

.upload-img {
  border: 1px solid black;
}

form > button {
  width: 20%;
  align-self: center;
  color: white;
  background-color: #31C48D;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

ul {
  list-style-type: none;
}
