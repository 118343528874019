.home-page {
  width: 75%;
  margin:  0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.home-page__listings {
  margin: 15px 0px;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  gap: 50px;
}

.home-page__banner {
  background-image: url('./banner.webp');
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(240, 255, 255, 0.5);
  padding: 50px;
  width: 45%;
  gap: 15px;
}

.banner__title > p:first-child {
  font-size: 32px;
}

.links {
  display: flex;
  gap: 25px;
}

a {
  color: inherit;
}

.fa-github, .fa-linkedin {
  font-size: 25px;
}

.loading-sprite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.loading-sprite > img {
  /* width: 100px; */
  height: 50px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .home-page__listings {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}
