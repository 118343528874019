.carousel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-btn {
  height: fit-content;
  border: none;
  border-radius: 25px;
  padding: 12px 15px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6) ;
  transition: 0.3s ease-in-out;
}

.img-btn:hover {
  background-color: #31C48D ;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
 .carousel {
  width: 80%;
 }
}
