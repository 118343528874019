.view-listing-container {
  width: 75%;
  margin: 0 auto;
  padding: 15px 0;
}

.view-listing__other {
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
}

.view-listing-info {
  margin-top: 20px;
  /* border: 1px solid red; */
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.view-listing-info > button {
  margin-top: 10px;
  padding: 15px 10px;
  border-radius: 25px;
  border: none;
  background-color: black;
  color: white;
  font-weight: bold;
  transition: 0.1s ease-in;
}

.view-listing-info > button:hover {
  background-color: rgba(1,1,1, 0.8);
}

.info {
  padding-left: 8px;
  letter-spacing: 1px;
}

.stars {
  height: 2px;
}

.info-price {
  font-size: 40px;
}

.info-desc {
  position: relative;
  top: 5%;
  text-align: justify;
  word-wrap: break-word;
}

.view-listings__reviews {
  position: relative;
  /* left: 6%; */
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 20px;
}

.view-listings__reviews > h2 {
  padding: 10px 0;
  text-decoration: underline;
}

.review {
  word-wrap: break-word;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.review-btns {
  display: flex;
  gap: 10px;
}

.add-review {
  border: 1px solid black;
  width: fit-content;
  background-color: var(--main-color);
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  transition: 0.3s ease-in;
}

.add-review:hover {
  box-shadow: 0 0 10px rgba(0, 174, 255, 0.8);
}

.add-review > * {
  background-color: inherit;
  color: white;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .view-listing__other {
    justify-content: space-around;
  }

  .view-listing__carousel {
    width: 70%;
  }

  .view-listing-info {
    position: relative;
    right: 8%;
  }
}
