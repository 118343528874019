.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.login-container > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.login-container > form > label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container > form > button {
  width: 100%;
}
