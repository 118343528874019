.checkout-container {
  /* padding: 15px; */
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
  width: 76%;
  margin: 0 auto;
  gap: 15px;
  padding: 10px;
}

.cart-items {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: fit-content;
  position: relative;
  top: 2%;
}

.checkout-details {
  /* border: 1px solid red; */
  width: 30%;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2px;
  width: 40%;
  margin-top: 50px;
}

.checkout-details > button {
  padding: 5px 10px;
  background-color: var(--main-color);
  border: none;
  border-radius: 5px;
}

.cart-items > button {
  padding: 10px;
  background-color: rgb(252, 68, 68);
  color: white;
  border: none;
  border-radius: 5px;
  width: fit-content;
}

.empty-msg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 90vh;
  justify-content: center;
  align-items: center;
  /* position: relative;
  top: 50%; */
}

.cart-amount {
  font-weight: bold;
  font-size: 25px;

}

.checkout-tax {
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
}

.checkout-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
