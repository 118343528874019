.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.login-page > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.login-page > form > label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.login-page > form > button {
  width: 100%;
}
