@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color:white;
}
.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.login-page > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.login-page > form > label {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.login-page > form > button {
  width: 100%;
}

.modal-button {
  background-color: white;
  border: none;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.login-container > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-gap: 10px;
  gap: 10px;
}

.login-container > form > label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container > form > button {
  width: 100%;
}

.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.signup > form {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
grid-gap: 10px;
gap: 10px;
}

.signup > form > label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup > form > button {
  width: 100%;
}

.profile-dropdown {
  position: absolute;
  border: 1px solid black;
  background-color: white;
  list-style: none;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.no-user-btns {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.no-user-btns > * {
  border: 1px solid black;
  background-color: white;
  padding: 5px 15px;
}

.hidden {
  display: none;
}

.nav-bar {
  padding: 15px;
  width: 75%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin: 0 auto;
  grid-gap: 15px;
  gap: 15px;
}

.nav-container {
  border-bottom: 1px solid grey;
}

.logo {
  background-image: url(/static/media/logo-no-background.2bf25e4b.png);
  height: 50px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-right-side {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  position: relative;
  justify-self: flex-end;
}

.fa-heart {
  font-size: 25px;
}

.fas:hover, .far:hover {
  cursor: pointer;
}

input[type="search"] {
  width: 100%;
  border: none;
  padding: 8px;
  font-size: 14px;
  background-color: transparent;
}

input[type="search"]:focus {
  border: none;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 24px;
  padding: 8px;
}

.search-icon {
  display: flex;
  align-items: center;
  padding: 4px;
}

.search-icon:hover {
  cursor: pointer;
}

.search-btn {
  border: none;
}

.create-listing {
  color: white;
  background-color: #31C48D;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

.profile-btn {
  border: none;
  background-color: white;
}

.fa-user-circle {
  font-size: 25px;
}

.logout-btn {
  background-color: #31C48D;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
}

.cart-logo {
  position: relative;
}

.cart-logo__amount {

  position: absolute;
  right: -25%;
  background-color: #31C48D;
  padding: 0 5px;
  font-size: 12px;
  color: white;
  border-radius: 10px;
  top: 10px;
}

.cart-logo__amount:hover {
  cursor: pointer;
}

.search-thing {
  /* border: none; */
  outline: none;
}

.listing-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0,0,0, 0.6);
  padding: 10px;
  border-radius: 5px;
  transition: 0.3s ease-in;
}

.listing-card-container:hover {
  box-shadow: 0 0 20px rgba(0,0,0, 0.6);;
}

.listing-card-container:hover {
  cursor: pointer;
}

.price-and-other {
  display: flex;
  justify-content: space-between;
}

.listing-card__details {
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
}

.listing-card__owner {
  margin: 10px 0;
}

.listing-card__buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.listing-card__price {
  font-weight: bold;
}

.listing-card__name {
  width: inherit
}

.stars {
  margin: 15px 0;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.like-card {
  position: absolute;
}

.fa-heart {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.delete-modal > div {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.delete-modal > div > button {
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  width: 5vw;
  transition: 0.2s ease-in;
}

.delete-modal > div > button:hover {
  box-shadow: 0 0 10px grey;
}

.delete-modal > div > button:first-child {
  background-color: #8388A4;
}

.delete-modal > div > button:last-child {
  background-color: #ED5E68;
}

.home-page {
  width: 75%;
  margin:  0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.home-page__listings {
  margin: 15px 0px;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-gap: 50px;
  gap: 50px;
}

.home-page__banner {
  background-image: url(/static/media/banner.df41d1bf.webp);
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(240, 255, 255, 0.5);
  padding: 50px;
  width: 45%;
  grid-gap: 15px;
  gap: 15px;
}

.banner__title > p:first-child {
  font-size: 32px;
}

.links {
  display: flex;
  grid-gap: 25px;
  gap: 25px;
}

a {
  color: inherit;
}

.fa-github, .fa-linkedin {
  font-size: 25px;
}

.loading-sprite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}

.loading-sprite > img {
  /* width: 100px; */
  height: 50px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .home-page__listings {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.form-container > form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 50%;
  grid-gap: 20px;
  gap: 20px;
}

.form-container > form > label {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.form-container > form > label> input, select, textarea {
  padding: 15px;
  border-radius: 5px;
}

textarea {
  resize: none;
  height: 100px;
}

.upload-img {
  border: 1px solid black;
}

form > button {
  width: 20%;
  align-self: center;
  color: white;
  background-color: #31C48D;
  padding: 10px;
  border: none;
  border-radius: 4px;
}

ul {
  list-style-type: none;
}

.review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}
.review-container > form > label > textarea {
  width: 100%;
}

.review-container > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  padding: 10px 0;
  grid-gap: 10px;
  gap: 10px;
}

.review-container > form > label:first-child {
  width: 30vw;
}

.carousel {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.img-btn {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border: none;
  border-radius: 25px;
  padding: 12px 15px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6) ;
  transition: 0.3s ease-in-out;
}

.img-btn:hover {
  background-color: #31C48D ;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
 .carousel {
  width: 80%;
 }
}

.error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.view-listing-container {
  width: 75%;
  margin: 0 auto;
  padding: 15px 0;
}

.view-listing__other {
  display: flex;
  justify-content: space-evenly;
  grid-gap: 15px;
  gap: 15px;
}

.view-listing-info {
  margin-top: 20px;
  /* border: 1px solid red; */
  width: 30%;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px;
}

.view-listing-info > button {
  margin-top: 10px;
  padding: 15px 10px;
  border-radius: 25px;
  border: none;
  background-color: black;
  color: white;
  font-weight: bold;
  transition: 0.1s ease-in;
}

.view-listing-info > button:hover {
  background-color: rgba(1,1,1, 0.8);
}

.info {
  padding-left: 8px;
  letter-spacing: 1px;
}

.stars {
  height: 2px;
}

.info-price {
  font-size: 40px;
}

.info-desc {
  position: relative;
  top: 5%;
  text-align: justify;
  word-wrap: break-word;
}

.view-listings__reviews {
  position: relative;
  /* left: 6%; */
  display: flex;
  flex-direction: column;

  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

.view-listings__reviews > h2 {
  padding: 10px 0;
  text-decoration: underline;
}

.review {
  word-wrap: break-word;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.review-btns {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.add-review {
  border: 1px solid black;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--main-color);
  padding: 10px 15px;
  border-radius: 4px;
  border: none;
  transition: 0.3s ease-in;
}

.add-review:hover {
  box-shadow: 0 0 10px rgba(0, 174, 255, 0.8);
}

.add-review > * {
  background-color: inherit;
  color: white;
}


@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .view-listing__other {
    justify-content: space-around;
  }

  .view-listing__carousel {
    width: 70%;
  }

  .view-listing-info {
    position: relative;
    right: 8%;
  }
}

.cartItem-container {
  display: flex;
  border: none;
  /* width: 80%; */
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
  padding: 25px 20px;
  border-radius: 10px;
  box-shadow: 0 0 18px grey;
}

.cartItem-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 350px;
  height: 250px;
}

.cartItem-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}

.cartItem-quantity {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.cartItem-price {
  position: relative;
  float: right;
}

.cartItem-quantity > button {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.cartItem-quantity > button:target {
  background-color: lightgray;
}

.cartItem-details > button {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: white;
  border: none;
  font-size: 15px;
  text-decoration: underline;
}

.checkout-container {
  /* padding: 15px; */
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
  width: 76%;
  margin: 0 auto;
  grid-gap: 15px;
  gap: 15px;
  padding: 10px;
}

.cart-items {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  top: 2%;
}

.checkout-details {
  /* border: 1px solid red; */
  width: 30%;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 2px;
  width: 40%;
  margin-top: 50px;
}

.checkout-details > button {
  padding: 5px 10px;
  background-color: var(--main-color);
  border: none;
  border-radius: 5px;
}

.cart-items > button {
  padding: 10px;
  background-color: rgb(252, 68, 68);
  color: white;
  border: none;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.empty-msg {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  min-height: 90vh;
  justify-content: center;
  align-items: center;
  /* position: relative;
  top: 50%; */
}

.cart-amount {
  font-weight: bold;
  font-size: 25px;

}

.checkout-tax {
  padding-bottom: 10px;
  border-bottom: 1px solid grey;
}

.checkout-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.tech {
  /* height: 50px; */
  width: 80px
}

.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  height: 90vh;
  font-size: 30px;
}

.thank-you-page > h1 {
  color: var(--main-color)
}

.tools-used-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.tools-used-container > h2 {
  text-decoration: underline;
}

.tools-used {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.search-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.search-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-top: 50px;
}

/* TODO Add site wide styles */

:root {
  --main-color: #31C48D;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
}

body {
  margin: 0 auto;
}

button:hover {
  cursor: pointer;
}

.container {
  min-height: 100vh - 100px;
  position: relative;
}

